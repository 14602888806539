import React from 'react';
import Nav from '../components/Nav/Nav';

function Terms() {
  return (
    <div>
      <Nav home/>
      <div className='wrapper-container  legal-container'>
        <div className='wrapper-content'>
          <div className='title-wrapper'>
            <h2>Mentions Légales</h2>
          </div>
          <h2>Éditeur du site</h2>
          <p>Fastline<br />
          SIRET : 828 983 254 00032<br />
          Responsable de publication : Mr Randy Lele Talla<br />
          Email : <a href="mailto:randy@fastlineapp.com">randy@fastlineapp.com</a></p>

          <h2>Hébergement</h2>
          <p>Le site est hébergé par Firebase,<br />
          Google LLC,<br />
          1600 Amphitheatre Parkway,<br />
          Mountain View, CA 94043, États-Unis.</p>

          <h2>Connexion via Google et gestion des données</h2>
          <p>Lors de la connexion via Google, nous collectons l'email et l'image de profil de l'utilisateur. Ces données sont stockées sur Firebase et ne sont pas partagées avec des tiers. L'utilisateur peut demander la suppression de son compte en envoyant un email à <a href="mailto:contact@fastlineapp.com">contact@fastlineapp.com</a>.</p>

        </div>
      </div>
    </div>
  );
}

export default Terms;