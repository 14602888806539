import React, { useContext, useEffect, useState } from 'react'
import {auth, provider, db, storage} from '../Firebase'
import { signInWithPopup , signOut} from 'firebase/auth'
import {addDoc,getDocs, collection, deleteDoc, doc,where, updateDoc, getDoc, orderBy, query} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import {v4} from "uuid"

const AuthContext = React.createContext()


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState()
    const [listChallenges] = useState()
    const [page, setPage] = useState('')
    const [newChallengeM, setNewChallengeM] = useState('')

    const [newVideoM, setNewVideoM] = useState('')

    const [currentChallenge, setCurrentChallenge] = useState()
    const [videoUrl, setVideoUrl] = useState(null)
    const [screenSize, setScreenSize] = useState(getCurrentDimension());



    const [videoLoading, setVideoLoading] = useState(false)


    const [isLoading, setIsLoading] = useState(false)
    const [isProducts, setIsProducts] = useState(false)

    const productsCollection = collection(db,"products")
    const categoryCollection = collection(db,"catégories")
    const restaurantCollection = collection(db,"restaurants")
    const [photoUrl, setPhotoUrl] = useState("")


    const [listRestaurants, setListRestaurants] = useState()
    const [currentRestaurant, setCurrentRestaurant] = useState()
    const [listProducts, setListProducts] = useState()
    const [currentProduct, setCurrentProduct] = useState()
    const [listCategories, setListCategories] = useState()
    const [currentCategory, setCurrentCategory] = useState()
    const [productM, setProductM] = useState('')
    const [categoryM, setCategoryM] = useState('')
    const [restaurantM, setRestaurantM] = useState('')
    const [language, setLanguage] = useState('');
    const [languages] = useState([
        {
            name: 'Anglais',
            origin: 'English',
            code:'en'
        },
        {
            name:'Espagnol',
            origin: 'Español',
            code:'es'
        },
        {
            name:'Italien',
            origin: 'Italiano',
            code:'it'
        },
        {
            name: 'Portugais',
            origin: 'Portuguese',
            code:'pt'
        },
        {
            name:'Allemand',
            origin: 'Deutsch',
            code:'de'
        },
        {
            name:'Néerlandais',
            origin: 'Nederlands',
            code:'nl'
        },
        {
            name: 'Chinois',
            origin: '中国人',
            code:'zh'
        },
        {
            name:'Arabe',
            origin:'العربية',
            code:'ar'
        },
    ]);

    const [translated , setTranslated] = useState({})
    const [translated2 , setTranslated2] = useState({})

    const [translatedOpt , setTranslatedOpt] = useState([])



    function getCurrentDimension(){
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
      }


     useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    
    
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])







     function usernane (email) {
       let result = email.indexOf("@");
       let username = email.slice(0, result-2)
       return username


     } 

     function name (displayName) {
        let result = displayName.indexOf(" ");
        let name = displayName.slice(0, result)
        return name
 
      } 

    function signup() {
        setIsLoading(true)
        signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user
             console.log(user)
             setCurrentUser(user)
             localStorage.setItem("connected", "true")
             /// console.log(result)
              setIsLoading(false)
             window.location.reload(false)
        })
        .catch((error) => {
            /// console.log(error);
            setIsLoading(false)
        })
    }

    function signout() {
        setIsLoading(true)
        signOut(auth)
        .then((result) => {
            /// console.log(result)
        localStorage.removeItem('connected');
        setCurrentUser()
        setIsLoading(false)
        window.location.reload(false)
        })
    }




    function postChallenges(number,creator, videos, user, active, url , picture, description) {
        setIsLoading(true)
        addDoc(productsCollection,{
            number,
           creator,
           videos,
            user,
            active,
            url,
            picture,
            description
                 } )
            .then((result) => {
        console.log(result)
        setIsLoading(false)
        setNewChallengeM("Nouveaux produits ajoutée !")
        })
    }


 ////////////////////new/////////////////////////////////////////////////////

 async function t(text, language) {
    const apiUrl = process.env.REACT_APP_TRANSLATE_API_URL;

    const res = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
            text: `${text}`,
            source_lang: "fr",
            target_lang: `${language}`,
        }),
        headers: { "Content-Type": "application/json" }
    });
    const data = await res.json();
    const trans = data.translations[0].text;
    setTranslated({
         ...translated,
        [language] : trans,
      
    })
 console.log(translated)
 }

 async function t2(text, language) {
    const apiUrl = process.env.REACT_APP_TRANSLATE_API_URL;

    const res = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
            text: `${text}`,
            source_lang: "fr",
            target_lang: `${language}`,
        }),
        headers: { "Content-Type": "application/json" }
    });
    const data = await res.json();
    const trans = data.translations[0].text;
    setTranslated2({
         ...translated2,
        [language] : trans,
    })
 console.log(translated2)
 }


 async function tOption(list, languages, ) {
    setTranslatedOpt([])
    const translatedList = [];
    console.log(languages)
    console.log(list)
    
    // Iterate over each object in the list
    for (const item of list) {
        const translations = {};
        // Translate the name attribute of the current object for each language
        for (const language of languages) {
            const res = await fetch("https://fastline-translate-0bc483dadeea.herokuapp.com/translate", {
                method: "POST",
                body: JSON.stringify({
                    text: `${item.name}`,
                    source_lang: "fr",
                    target_lang: `${language}`,
                }),
                headers: { "Content-Type": "application/json" }
            });
            const data = await res.json();
            translations[language] = data.translations[0].text;
        }
        
        // Push the translated object into the translatedList array
        translatedList.push({
            ...item,
            translations: translations
        });

        setTranslatedOpt(translatedList)
    }

    console.log(translatedList);
    console.log(translatedOpt) // Logging the translated list
}




 function changeLanguage(language) {
    setLanguage(language)
    console.log(language)
}





    function getRestaurants() {
        setIsLoading(true)
        const restaurants = query(collection(db, "restaurants"), orderBy("name"));
        getDocs(restaurants)
            .then((data) => {
                setListRestaurants(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
                setIsLoading(false)
            })
  
    }


    function getRestaurant(id) {
        setIsLoading(true)
        const restaurant = doc(db, "restaurants", id)
        getDoc(restaurant)
            .then((result) => {
                setCurrentRestaurant(result.data())
                /// console.log(currentChallenge)
                setIsLoading(false)
            })
    }


    function getProducts(id) {
        setIsLoading(true)
        const products = query(collection(db, "products"), where("restaurant", "==", id),  orderBy("position"));
        getDocs(products)
            .then((data) => {
                setListProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
                setIsLoading(false)
                setIsProducts(true)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }


    function getProduct(id) {
        setIsLoading(false)
        const product = doc(db, "products", id)
        getDoc(product)
            .then((result) => {
                setCurrentProduct(result.data())
                console.log(currentProduct)
                /// console.log(currentChallenge)
                setIsLoading(false)
            })
    }


    function getCategories(id) {
        setIsLoading(true)
        const categories = query(collection(db, "catégories"),where("restaurant", "==", id), orderBy("position"), );
        getDocs(categories)
            .then((data) => {
                setListCategories(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
                setIsLoading(false)
            })
    }


    function getcategory(id) {
        setIsLoading(false)
        const category = doc(db, "catégories", id)
        getDoc(category)
            .then((result) => {
                setCurrentCategory(result.data())
                console.log(currentCategory)
                /// console.log(currentChallenge)
                setIsLoading(false)
            })
    }


    function postProduct(name,price, description, category, picture, status,
         position, restaurant, translated, translatedOpt, translated2, allergens, label) {
        setIsLoading(true)
        addDoc(productsCollection,{
            name,
            price,
            description,
            category,
            picture,
            status,
            position,
            restaurant,
            translated,
            translatedOpt,
            translated2,
            allergens,
            label
                         } )
            .then((result) => {
           console.log(result)
        setIsLoading(false)
        setProductM("Nouveaux produit ajoutée.")
        setTimeout(() => {
            setProductM("")
        }, 3000);
         setPhotoUrl("")
         setTranslated({})
         setTranslated2({})
         setTranslatedOpt([])
            })
            

           
    }



    function postCategory(name, restaurant, status, position, translated) {
        setIsLoading(true)
        addDoc(categoryCollection,{
            name,
            restaurant,
            status,
            position,
            translated
                 } )
            .then((result) => {
           console.log(result)
        setIsLoading(false)
        setCategoryM("Nouvelle catégorie ajoutée.")
        setTimeout(() => {
            setCategoryM("")
        }, 3000);
        setTranslated({})
        })
    }


    function postRestaurant(owner, name, adress,type,  hours, review, photo, status,defaultL, translated, social) {
        setIsLoading(true)
        addDoc(restaurantCollection,{
            owner, 
            name,
            adress,
            type,  
            hours, 
            review, 
            photo, 
            status,
            defaultL,
            translated,
            social
                 } )
            .then((result) => {
           console.log(result)
        setIsLoading(false)
        setRestaurantM("Nouveaux restaurant ajoutée !")
        setTimeout(() => {
            setRestaurantM("")
        }, 3000); 
        setPhotoUrl("")
        setTranslated({})
        })
    }


    function updateRestaurant(id,owner, name, adress,type,  hours, review, photo, status,defaultL, translated, social) {
        setIsLoading(true)
        const restaurantDb = doc(db, "restaurants", id)
        const fields = {
            owner, 
            name,
            adress,
            type,  
            hours, 
            review, 
            photo, 
            status,
            defaultL,
            translated,
            social
        }

        updateDoc(restaurantDb, fields)
            .then((result) => {
                console.log(result)
                setIsLoading(false)
                setRestaurantM("Sauvegardé.")
                setTimeout(() => {
                    setRestaurantM("")
                }, 3000); 
                setPhotoUrl("")
            })
    }


    function updateCategory(id,name, restaurantId, status, position, translated) {
        setIsLoading(true)
        const categoryDb = doc(db, "catégories", id)
        const fields = {
            name:name,
            restaurant:restaurantId,
            status:status,
            position: position,
            translated: translated,
        }

        updateDoc(categoryDb, fields)
            .then((result) => {
                console.log(result)
                setIsLoading(false)
                setCategoryM("Sauvegardé.")
                setTimeout(() => {
                    setCategoryM("")
                }, 3000);
            })
    }


    function updateProduct(id, name,price, description, category, picture, status, position, translated, translatedOpt , translated2, allergens, label) {
        setIsLoading(true)
        const productDb = doc(db, "products", id)
        const fields = {
            name,
            price,
            description,
            category,
            picture,
            status,
            position,
            translated,
            translatedOpt,
            translated2, 
            allergens,
            label
        }

        updateDoc(productDb, fields)
            .then((result) => {
                console.log(result)
                setIsLoading(false)
                setProductM("Sauvegardé.")
                setTimeout(() => {
                    setProductM("")
                }, 3000);
                setPhotoUrl("")
            })
    }


    function deleteProduct(id) {
        const productDb = doc(db, "products", id)

        deleteDoc(productDb)
            .then((result) => {
                console.log(result)
                setIsLoading(false)
                window.history.back()
            })
    }


    function deleteRestaurant(id) {
        const restaurantDb = doc(db, "restaurants", id)

        deleteDoc(restaurantDb)
            .then((result) => {
                console.log(result)
                setIsLoading(false)
            })
    }



    function deleteCategory(id) {
        const categoryDb = doc(db, "catégories", id)

        deleteDoc(categoryDb)
            .then((result) => {
                console.log(result)
                setIsLoading(false)
                window.history.back()
            })
    }


    function addPhoto(photo) {
        setIsLoading(true)
       if(photo === null)  return;
       const photoRef = ref(storage, `photos/${photo.name + v4()}`);
       uploadBytes(photoRef, photo)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                .then((url) => {
               setPhotoUrl(url)
               /// console.log(videoUrl)
               setNewVideoM('Your video has been sent. If it is validated by the creator, it will appear below.')  
        setIsLoading(false)
                })
                     

        })
    }













    function getChallenge2(id) {
        const challenge = doc(db, "challenges", id)
         getDoc(challenge)
            .then((result) => {
                         setCurrentChallenge(result.data())

            /// console.log(currentChallenge)
        })
    }





   async function deleteChallenges(id) {
        const challenge = doc(db, "challenges", id)

    await deleteDoc(challenge )
    }



   async function participation(id, participant) {
        const challenge = doc(db, "challenges", id)
    const fields = { participants :[...participant]}

await updateDoc(challenge, fields )
}



async function likeVideo(id, video) {
    const currentVideo = doc(db, "challenges", id)
const fields = { videos :[...video]}

await updateDoc(currentVideo, fields )
}


async function deleteVideo(id, video) {
    const currentVideo = doc(db, "challenges", id)
const fields = { videos :[...video]}

await updateDoc(currentVideo, fields )
}


function newVideo(id, videoData) {
    const challenge = doc(db, "challenges", id)
const fields = { videos :[...videoData]}

 updateDoc(challenge, fields )
 .then(() => {
    setVideoUrl(null)
 })

}







        function pages(whereIAm) {
          setPage(whereIAm)
          setNewChallengeM("")

          /// console.log(page)
        }




        function addVideo(video) {
            setVideoLoading(true)
           if(video === null)  return;
           const videoRef = ref(storage, `videos/${video.name}`);
           uploadBytes(videoRef, video)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then((url) => {
                   setVideoUrl(url)
                   /// console.log(videoUrl)
                   setNewVideoM('Your video has been sent. If it is validated by the creator, it will appear below.')  
            setVideoLoading(false)
                    })
                         
 
            })
        }




useEffect(() => {
const unsubcribe = auth.onAuthStateChanged(user => {
    setCurrentUser(user)
})
return unsubcribe
}, [])




   const value = {
    currentUser,
    signup,
    signout,
    auth,
    usernane,
    name,
    postChallenges,
    isLoading,
    listChallenges,
    page,
    pages,
    deleteChallenges,
    newChallengeM,
    participation,
    currentChallenge,
    addVideo,
    newVideo,
    videoUrl,
    deleteVideo,
    likeVideo,
    screenSize,
    getCurrentDimension,
    newVideoM,
    videoLoading,
    getChallenge2,
    getRestaurants,
    listRestaurants,
    getRestaurant,
    currentRestaurant,
    getProducts,
    listProducts,
    getProduct,
    currentProduct,
    getCategories,
    getcategory,
    listCategories,
    currentCategory,
    postProduct,
    postCategory,
    postRestaurant,
    updateRestaurant,
    updateCategory,
    updateProduct,
    deleteProduct,
    deleteRestaurant,
    deleteCategory,
    categoryM,
    productM,
    restaurantM,
    photoUrl,
    addPhoto,
    changeLanguage,
    language,
    t,
    t2,
    tOption,
    translatedOpt,
    translated,
    translated2,
    languages,
    isProducts




   }

  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  )
}

